import {
  authControllerSignIn as mutationFn,
  AuthDtoReq,
} from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useUserStore } from "@/entities";
import { useMutation } from "@tanstack/react-query";

type FormData = AuthDtoReq;
export function useSignInForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const { login } = useUserStore();

  const formMutation = useMutation({
    mutationFn,
    async onSuccess({ access_token }) {
      login(access_token);
      reset();
    },
  });

  return {
    register,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
    isLoading: formMutation.isPending,
    errors,
  };
}
