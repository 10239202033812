import { FC, ReactNode } from "react";
import styled from "styled-components";
import { IconClose } from "@/shared/ui/icons";

interface Props {
  children: ReactNode;
  className?: string;
  onCrossClick?: () => void;
  isLoading?: boolean;
}

export const UiPaper: FC<Props> = ({
  children,
  className,
  onCrossClick,
  isLoading,
}) => {
  return (
    <Wrapper $isCross={!!onCrossClick} className={className}>
      {!!onCrossClick && (
        <CrossWrapper
          onClick={onCrossClick}
          className={`${isLoading ? "opacity-50 cursor-not-allowed" : ""} `}
        >
          <IconClose height="25px" />
        </CrossWrapper>
      )}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isCross: boolean }>`
  background-color: #fff;
  padding: 30px 24px;
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0.1) 0 20px 25px -5px,
    rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
  position: relative;
`;

const CrossWrapper = styled.button`
  position: absolute;
  top: 10px;
  right: 5px;
  width: fit-content;
`;
