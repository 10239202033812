import { UiBackdrop, UiButton, UiInput, UiPaper } from "@/shared/ui";
import { useUserStore } from "@/entities";
import styled from "styled-components";
import { useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import styles from "./styles.module.scss";
import { ChangeProcess } from "./change-process";
import { useTranslation } from "react-i18next";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";

export const ChangeEmail = () => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const [confirmChangeModal, setConfirmChangeModal] = useState(false);

  return (
    <>
      <Wrapper>
        <UiInput name="currentEmail" value={userStore.email} readOnly />
        <UiButton variant="white" onClick={() => setConfirmChangeModal(true)}>
          <div>
            {capitalize(t(LANG_KEYS.CHANGE.key, LANG_KEYS.CHANGE.value))}
          </div>
        </UiButton>
      </Wrapper>

      <AnimatePresence mode="wait">
        {confirmChangeModal && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            className="relative z-50"
          >
            <UiBackdrop handleClose={() => setConfirmChangeModal(false)}>
              <UiPaper className={styles.confirmChangeEmailCard}>
                <ChangeProcess onClose={() => setConfirmChangeModal(false)} />
              </UiPaper>
            </UiBackdrop>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;
