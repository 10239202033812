import {
  appControllerLeaveSuggestion as mutationFn,
  LeaveSuggestionDtoReq,
} from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";

type FormData = LeaveSuggestionDtoReq;
export function useSuggestionForm(closeModal: () => void) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    values: {
      email: "",
      name: "",
      text: "",
    },
  });

  const formMutation = useMutation({
    mutationFn,
    async onSuccess() {
      const text = capitalize(
        t(
          LANG_KEYS.THANK_YOU_FOR_YOUR_SUGGESTION.key,
          LANG_KEYS.THANK_YOU_FOR_YOUR_SUGGESTION.value,
        ),
      );
      reset();
      closeModal();
      toast.info(text);
    },
  });

  return {
    register,
    errors,
    handleSubmit: handleSubmit((values) => {
      const payload: FormData = {
        text: values.text,
      };

      if (values.email) {
        payload.email = values.email;
      }

      if (values.name) {
        payload.name = values.name;
      }

      formMutation.mutate(payload);
    }),
    isLoading: formMutation.isPending,
  };
}
