import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UiBackdrop, UiButton, UiPaper } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import { AnimatePresence, motion as m } from "framer-motion";
import styles from "./styles.module.scss";
import styled from "styled-components";
import { IconDanger } from "@/shared/ui/icons";
import { capitalize } from "@/shared/utils";
import { useDeleteAccountForm } from "./model.ts";

export const DeleteAccount = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { isLoading, handleSubmit } = useDeleteAccountForm();

  const handleClose = () => {
    if (isLoading) return;
    setShowModal(false);
  };

  return (
    <>
      <UiButton
        type="button"
        variant="danger"
        onClick={() => setShowModal(true)}
      >
        <div>
          {t(
            LANG_KEYS.DELETE_MY_ACCOUNT.key,
            LANG_KEYS.DELETE_MY_ACCOUNT.value,
          )}
        </div>
      </UiButton>

      <AnimatePresence mode="wait">
        {showModal && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            className="relative z-50"
          >
            <UiBackdrop handleClose={handleClose}>
              <UiPaper className={styles.deleteAccountCard}>
                <CardInner>
                  <IconWrapper>
                    <IconDanger className="h-6 w-6 text-red-600" />
                  </IconWrapper>
                  <CardContent>
                    <CardTitle>
                      {capitalize(
                        t(
                          LANG_KEYS.DEACTIVATE_ACCOUNT.key,
                          LANG_KEYS.DEACTIVATE_ACCOUNT.value,
                        ),
                      )}
                    </CardTitle>
                    <CardText>
                      {t(
                        LANG_KEYS
                          .ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_YOUR_ACCOUNT.key,
                        LANG_KEYS
                          .ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_YOUR_ACCOUNT
                          .value,
                      )}
                    </CardText>
                    <CardFooter>
                      <FooterInner>
                        <UiButton
                          type="button"
                          variant="white"
                          onClick={handleClose}
                        >
                          <div>
                            {capitalize(
                              t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value),
                            )}
                          </div>
                        </UiButton>
                        <UiButton
                          type="button"
                          variant="danger"
                          onClick={handleSubmit}
                          loading={isLoading}
                        >
                          <div>
                            {capitalize(
                              t(LANG_KEYS.DELETE.key, LANG_KEYS.DELETE.value),
                            )}
                          </div>
                        </UiButton>
                      </FooterInner>
                    </CardFooter>
                  </CardContent>
                </CardInner>
              </UiPaper>
            </UiBackdrop>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
};

const CardInner = styled.div`
  display: flex;
  gap: 16px;
`;
const CardContent = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 1;
`;
const CardFooter = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
`;
const FooterInner = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const IconWrapper = styled.div`
  background-color: #fee2e2;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardTitle = styled.h3`
  color: #111827;
  font-weight: 600;
  font-size: 16px;
`;
const CardText = styled.p`
  color: #6b7280;
  font-size: 14px;
`;
