export const SUPPORTED_FILE_FORMATS = [
  // AUDIO
  "mp3",
  "aac",
  "ogg",
  "flac",
  "wav",
  "m4a",
  "wma",
  // VIDEO
  "mp4",
  "mov",
  "mkv",
  "avi",
  "webm",
];
