import { UiMainHeader } from "./ui-main-header";
import { UiMainFooter } from "./ui-main-footer";
import { Outlet, useLocation } from "react-router-dom";
import { AnimatePresence, motion as m } from "framer-motion";

export const UiMainLayout = () => {
	const location = useLocation();
	return (
		<div className="bg-[#F3F4F6] flex flex-col min-h-screen">
			<UiMainHeader />
			<AnimatePresence mode="wait">
				<m.main
					key={location.pathname}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.75, ease: "easeOut" }}
					exit={{ opacity: 1 }}
					className="flex-1"
				>
					<Outlet />
				</m.main>
			</AnimatePresence>
			<UiMainFooter />
		</div>
	);
};
