import { UiButton, UiInput, UiInputPass } from "@/shared/ui";
import styled from "styled-components";
import { useSignUpForm } from "./model";
import { useTranslation } from "react-i18next";
import { LANG_KEYS } from "@/shared/constants";

export const SignUpForm = () => {
	const { handleSubmit, errors, register, isLoading } = useSignUpForm();
	const { t } = useTranslation();

	return (
		<Form onSubmit={handleSubmit}>
			<div className="inputs">
				<UiInput
					label={t(
						LANG_KEYS.EMAIL_ADDRESS.key,
						LANG_KEYS.EMAIL_ADDRESS.value
					)}
					error={errors?.email?.message}
					{...register("email", {
						required: {
							value: true,
							message: t(
								LANG_KEYS.REQUIRED.key,
								LANG_KEYS.REQUIRED.value
							),
						},
						pattern: {
							value: /\S+@\S+\.\S+/,
							message: t(
								LANG_KEYS.INVALID_EMAIL_FORMAT.key,
								LANG_KEYS.INVALID_EMAIL_FORMAT.value
							),
						},
					})}
				/>
				<UiInputPass
					id="password"
					label={t(LANG_KEYS.PASSWORD.key, LANG_KEYS.PASSWORD.value)}
					error={errors?.password?.message}
					{...register("password", {
						required: {
							value: true,
							message: t(
								LANG_KEYS.REQUIRED.key,
								LANG_KEYS.REQUIRED.value
							),
						},
					})}
				/>
				<UiInputPass
					id="confirmPassword"
					label={t(
						LANG_KEYS.CONFIRM_PASSWORD.key,
						LANG_KEYS.CONFIRM_PASSWORD.value
					)}
					error={errors?.confirmPassword?.message}
					{...register("confirmPassword", {
						required: {
							value: true,
							message: t(
								LANG_KEYS.REQUIRED.key,
								LANG_KEYS.REQUIRED.value
							),
						},
					})}
				/>
			</div>

			<UiButton
				variant="main"
				className="w-full"
				type="submit"
				loading={isLoading}
			>
				<div>{t(LANG_KEYS.SIGN_UP.key, LANG_KEYS.SIGN_UP.value)}</div>
			</UiButton>
		</Form>
	);
};

const Form = styled.form`
	.inputs {
		display: flex;
		flex-direction: column;
		gap: 25px;
		margin-bottom: 24px;
	}
`;
