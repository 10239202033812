import { useForm } from "react-hook-form";
import {
	authControllerSignUp as mutationFn,
	AuthDtoReq,
} from "@/shared/api/generated.ts";
import { useMutation } from "@tanstack/react-query";
import { useUserStore } from "@/entities";
import { useTranslation } from "react-i18next";
import { LANG_KEYS } from "@/shared/constants";

type FormData = AuthDtoReq & { confirmPassword: string };

export function useSignUpForm() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		reset,
	} = useForm<FormData>();

	const { login } = useUserStore();
	const { t } = useTranslation();
	const formMutation = useMutation({
		mutationFn,
		async onSuccess({ access_token }) {
			login(access_token);
			reset();
		},
	});

	const NOT_MATCH_PASSWORDS_ERROR = {
		type: "custom",
		message: t(
			LANG_KEYS.PASSWORDS_DONT_MATCH.key,
			LANG_KEYS.PASSWORDS_DONT_MATCH.value
		),
	};

	return {
		register,
		handleSubmit: handleSubmit((values) => {
			if (values.password !== values.confirmPassword) {
				setError("password", NOT_MATCH_PASSWORDS_ERROR);
				setError("confirmPassword", NOT_MATCH_PASSWORDS_ERROR);
				return;
			}
			formMutation.mutate({
				email: values.email,
				password: values.password,
			});
		}),
		isLoading: formMutation.isPending,
		errors,
	};
}
