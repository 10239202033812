import { SUPPORTED_FILE_FORMATS } from "@/shared/constants";
import { useTranscribeSocket } from "./model.ts";
import { UiSpinner } from "@/shared/ui";

export const FileTranscribe = () => {
  const {
    handleFileChange,
    received,
    chunks,
    original,
    fileInputRef,
    loading,
  } = useTranscribeSocket();
  return (
    <div className="p-10">
      <div className="flex items-center flex-col mb-5">
        <h3>Socket File Original Text Transcribe Demo</h3>
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          accept={SUPPORTED_FILE_FORMATS.map((ext) => `.${ext}`).join(",")}
        />
      </div>

      <div className="flex justify-center">{loading && <UiSpinner />}</div>

      <div className="flex gap-[3]">
        <div className="flex-1 flex flex-col items-center max-w-[500px]">
          <h5 className="font-semibold">Steps</h5>
          <ul>
            {received.map((item, i) => {
              return (
                <li key={`received-${i}`}>
                  <b>{i + 1}.</b>
                  &nbsp;
                  <p className="capitalize whitespace-nowrap">{item}</p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex-1 flex flex-col items-center max-w-[500px]">
          <h5 className="font-semibold">Chunks</h5>
          <ul>
            {chunks.map((item, i) => {
              return (
                <li key={`chunk-${i}`}>
                  <b>{i + 1}.</b>
                  &nbsp;<span>{item}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {!!original && (
        <div className="max-w-[900px] flex flex-col items-center text-center my-10">
          <h5 className="font-semibold">Full Original text</h5>
          <p>{original}</p>
        </div>
      )}
    </div>
  );
};
