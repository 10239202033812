import { ReactNode, useEffect, useState } from "react";
import { UiLoading, UiShow } from "@/shared/ui";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion as m } from "framer-motion";
import { useLangStore, useRecordOptionsStore, useUserStore } from "@/entities";
import { LangOption } from "@/shared/types.ts";
import { userControllerGetRecordOptions } from "@/shared/api/generated";

export const PreloadProvider = ({ children }: { children: ReactNode }) => {
  const [isDone, setDone] = useState(false);
  const { i18n } = useTranslation();
  const { setData, lang } = useLangStore();
  const { client_access_token, access_token, generateClientAuth } =
    useUserStore();

  const { setOptions } = useRecordOptionsStore();

  const handlePreload = async (err: any, data: any) => {
    if (err) {
      console.log(err);
      return;
    }

    const res: LangOption[] = Object.keys(data).map((item) => ({
      name: data[item].name,
      nativeName: data[item].nativeName,
      isReferenceLanguage: data[item].isReferenceLanguage,
      code: item,
    }));
    setData(res);
    await i18n.changeLanguage(lang);

    let token = "";

    if (!client_access_token) {
      token = await generateClientAuth();
    } else {
      token = access_token;
    }

    const options = await userControllerGetRecordOptions();
    setOptions(options);

    console.log("preload provided auth token and record options");
    console.log({ token });

    setDone(true);
  };

  const fetchLangs = () => {
    i18n.services.backendConnector.backend.getLanguages(handlePreload);
  };

  useEffect(() => {
    fetchLangs();
  }, []);

  return (
    <>
      <UiShow>
        <UiShow.When isTrue={!isDone}>
          <AnimatePresence mode="wait">
            <m.div
              transition={{ duration: 0.75, ease: "easeOut" }}
              exit={{ opacity: 0 }}
            >
              <UiLoading fullScreen />
            </m.div>
          </AnimatePresence>
        </UiShow.When>
        <UiShow.Else>
          <AnimatePresence mode="wait">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.75, ease: "easeOut" }}
              exit={{ opacity: 0 }}
            >
              {children}
            </m.div>
          </AnimatePresence>
        </UiShow.Else>
      </UiShow>
    </>
  );
};
