import "@/shared/ui/style/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "./localization";

import { ToastContainer } from "react-toastify";
import { PreloadProvider, QueryProvider } from "./providers";
import { RouterSetup } from "./router";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <QueryProvider>
      <PreloadProvider>
        <BrowserRouter>
          <RouterSetup />
        </BrowserRouter>
      </PreloadProvider>
      <ToastContainer stacked closeOnClick />
    </QueryProvider>
  );
}

export default App;
