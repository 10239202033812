export const ROUTER_PATHS = {
  HOME: "/",
  AUTH: "/auth",
  AUTH_SIGN_UP: "/auth/sign-up",
  AUTH_SIGN_IN: "/auth/sign-in",
  AUTH_RESET_PASSWORD: "/auth/reset-password",
  USER: "/user",
  USER_RESET_PASSWORD: "/user/reset-password/:code",
  USER_CONFIRM_EMAIL: "/user/confirm-email/:code",
  ACCOUNT: "/account",
  ACCOUNT_SETTINGS: "/account/settings",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICE: "/terms-of-service",
  PRICING: "/pricing",
};
