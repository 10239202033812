import { useRecordOptionsStore } from "@/entities";
import { UiButton, UiSelectable, UiShow, UiTextarea } from "@/shared/ui";
import { useRecordOptionsForm } from "./model";
import {
	GetRecordOptionsDtoResNotesLang,
	GetRecordOptionsDtoResNumberOfSpeakers,
	GetRecordOptionsDtoResSummaryLength,
	GetRecordOptionsDtoResSummaryView,
} from "@/shared/api/generated";
import styled from "styled-components";
import { FC, useEffect, useState } from "react";
import { AddCustomPromptForm } from "../add-custom-prompt/ui";
import { EditCustomPromptForm } from "../edit-custom-prompt/ui";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS } from "@/shared/constants";

type PreviewPrompt = "editable" | "readable";
interface Props {
	onFinish: () => void;
}

export const EditRecordOptionsForm: FC<Props> = ({ onFinish }) => {
	const recordOptionsStore = useRecordOptionsStore();
	const { t } = useTranslation();
	const { handleSubmit, isLoading, setValue, defaultValues, control, watch } =
		useRecordOptionsForm(onFinish);

	const watcherSelectedRecordSummaryStyleId = watch(
		"selectedRecordSummaryStyleId"
	);

	const [showCustomSummaryStyleForm, setShowCustomSummaryStyleForm] =
		useState(false);

	const getPreviewPromptById = (id: string): PreviewPrompt => {
		const summary = recordOptionsStore.summaryStyle.find(
			(item) => item.id == id
		);
		if (!summary) return "readable";

		return summary.type == "CUSTOM" ? "editable" : "readable";
	};

	const [previewPromptType, setPreviewPromptType] = useState<PreviewPrompt>(
		getPreviewPromptById(defaultValues.selectedRecordSummaryStyleId)
	);

	const [selectedSummaryTitle, setSelectedSummaryTitle] = useState("");
	const [selectedSummaryText, setSelectedSummaryText] = useState("");

	const getSelectedPromptText = (id: string) => {
		const summary = recordOptionsStore.summaryStyle.find(
			(item) => item.id == id
		);
		if (!summary) return "";
		return summary.text;
	};

	const getSelectedPromptType = (id: string) => {
		const summary = recordOptionsStore.summaryStyle.find(
			(item) => item.id == id
		);
		if (!summary) return "CUSTOM";
		return summary.type;
	};

	const getSelectedPromptTitle = (id: string) => {
		const summary = recordOptionsStore.summaryStyle.find(
			(item) => item.id == id
		);
		if (!summary) return "";
		return summary.title;
	};

	const handleUpdateAfterEdit = (title: string, text: string) => {
		setSelectedSummaryTitle(title);
		setSelectedSummaryText(text);
	};

	const translatePrompt = (title: string, text: string) => {
		const key = title.split(" ").join("_").toUpperCase() + "_PROMPT";
		return t(key, text);
	};

	useEffect(() => {
		if (watcherSelectedRecordSummaryStyleId) {
			const selectedTitle = getSelectedPromptTitle(
				watcherSelectedRecordSummaryStyleId
			);
			const selectedText = getSelectedPromptText(
				watcherSelectedRecordSummaryStyleId
			);

			const previewPromptType = getPreviewPromptById(
				watcherSelectedRecordSummaryStyleId
			);

			const selectedSummaryType = getSelectedPromptType(
				watcherSelectedRecordSummaryStyleId
			);

			const promptText =
				selectedSummaryType == "DEFAULT"
					? translatePrompt(selectedTitle, selectedText)
					: selectedText;

			setSelectedSummaryTitle(selectedTitle);
			setSelectedSummaryText(promptText);
			setPreviewPromptType(previewPromptType);
		}
	}, [watcherSelectedRecordSummaryStyleId]);
	return (
		<Form onSubmit={handleSubmit}>
			<Content>
				<Controller
					name="numberOfSpeakers"
					control={control}
					render={({ field }) => (
						<UiSelectable
							label={t(
								LANG_KEYS.NUMBER_OF_SPEAKERS.key,
								LANG_KEYS.NUMBER_OF_SPEAKERS.value
							)}
							value={field.value}
							options={Object.keys(
								GetRecordOptionsDtoResNumberOfSpeakers
							).map((item) => ({
								value: item,
								name: t(item, item.split("_").join(" ")),
							}))}
							onSelect={({ value }) => field.onChange(value)}
						/>
					)}
				/>

				<Controller
					control={control}
					name="notesLang"
					render={({ field }) => (
						<UiSelectable
							hint={t(
								LANG_KEYS
									.SELECT_THE_LANGUAGE_IN_WHICH_YOUR_NOTES_WILL_BE_DISPLAYED
									.key,
								LANG_KEYS
									.SELECT_THE_LANGUAGE_IN_WHICH_YOUR_NOTES_WILL_BE_DISPLAYED
									.value
							)}
							label={t(
								LANG_KEYS.OUTPUT_NOTES_LANGUAGE.key,
								LANG_KEYS.OUTPUT_NOTES_LANGUAGE.value
							)}
							value={field.value}
							onSelect={({ value }) => field.onChange(value)}
							options={Object.keys(
								GetRecordOptionsDtoResNotesLang
							).map((item) => ({
								value: item,
								name: t(item, item),
							}))}
						/>
					)}
				/>
				{!showCustomSummaryStyleForm && (
					<>
						<Controller
							control={control}
							name="selectedRecordSummaryStyleId"
							render={({ field }) => (
								<UiSelectable
									heading={t(
										LANG_KEYS.DEFAULT_SUMMARY_STYLES.key,
										LANG_KEYS.DEFAULT_SUMMARY_STYLES.value
									)}
									hint={t(
										LANG_KEYS
											.SELECT_THE_STYLE_IN_WHICH_YOUD_LIKE_TO_VIEW_YOUR_NOTES
											.key,
										LANG_KEYS
											.SELECT_THE_STYLE_IN_WHICH_YOUD_LIKE_TO_VIEW_YOUR_NOTES
											.value
									)}
									label={t(
										LANG_KEYS.CHOOSE_SUMMARY_STYLE.key,
										LANG_KEYS.CHOOSE_SUMMARY_STYLE.value
									)}
									defaultValue={
										defaultValues.selectedRecordSummaryStyleId
									}
									value={field.value}
									options={recordOptionsStore.summaryStyle
										.filter(
											(item) => item.type == "DEFAULT"
										)
										.map((item) => ({
											value: item.id,
											name: t(
												item.title
													.split(" ")
													.join("_")
													.toUpperCase(),
												item.title
											),
										}))}
									secondOptions={recordOptionsStore.summaryStyle
										.filter((item) => item.type == "CUSTOM")
										.map((item) => ({
											value: item.id,
											name: item.title,
										}))}
									secondHeading={t(
										LANG_KEYS.CUSTOM_SUMMARY_STYLES.key,
										LANG_KEYS.CUSTOM_SUMMARY_STYLES.value
									)}
									onSelect={({ value }) => {
										field.onChange(value);
									}}
									children={
										<InnerFormWrapper>
											<UiButton
												className="w-full"
												variant="main"
												type="button"
												onClick={() =>
													setShowCustomSummaryStyleForm(
														true
													)
												}
											>
												<div>
													{t(
														LANG_KEYS
															.ADD_YOUR_PROMPT
															.key,
														LANG_KEYS
															.ADD_YOUR_PROMPT
															.value
													)}
												</div>
											</UiButton>
										</InnerFormWrapper>
									}
								/>
							)}
						/>
						<UiShow>
							<UiShow.When
								isTrue={previewPromptType == "readable"}
							>
								<UiTextarea
									name="read-default-prompt"
									readOnly
									disabled
									value={selectedSummaryText}
								/>
							</UiShow.When>
							<UiShow.When
								isTrue={previewPromptType == "editable"}
							>
								<EditCustomPromptForm
									updatedOnEdit={handleUpdateAfterEdit}
									setSelectedId={(id: string) => {
										setValue(
											"selectedRecordSummaryStyleId",
											id
										);
									}}
									defaultValues={{
										title: selectedSummaryTitle,
										text: selectedSummaryText,
										id: watcherSelectedRecordSummaryStyleId,
									}}
								/>
							</UiShow.When>
						</UiShow>
					</>
				)}
				{showCustomSummaryStyleForm && (
					<AddCustomPromptForm
						onCancel={() => setShowCustomSummaryStyleForm(false)}
						setSelectedId={(id: string) => {
							setValue("selectedRecordSummaryStyleId", id);
						}}
					/>
				)}
				<Controller
					name="summaryView"
					control={control}
					render={({ field }) => (
						<UiSelectable
							hint={t(
								LANG_KEYS
									.SELECT_THE_STYLE_IN_WHICH_YOUD_LIKE_TO_VIEW_YOUR_NOTES
									.key,
								LANG_KEYS
									.SELECT_THE_STYLE_IN_WHICH_YOUD_LIKE_TO_VIEW_YOUR_NOTES
									.value
							)}
							label={t(
								LANG_KEYS.DEFAULT_SUMMARY_VIEW.key,
								LANG_KEYS.DEFAULT_SUMMARY_VIEW.value
							)}
							value={field.value}
							options={Object.keys(
								GetRecordOptionsDtoResSummaryView
							).map((item) => ({
								value: item,
								name: t(item, item.split("_").join(" ")),
							}))}
							onSelect={({ value }) => field.onChange(value)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="summaryLength"
					render={({ field }) => (
						<UiSelectable
							label={t(
								LANG_KEYS.CHOOSE_SUMMARY_LENGTH.key,
								LANG_KEYS.CHOOSE_SUMMARY_LENGTH.value
							)}
							value={field.value}
							options={Object.keys(
								GetRecordOptionsDtoResSummaryLength
							).map((item) => ({
								value: item,
								name: t(item, item.split("_").join(" ")),
							}))}
							onSelect={({ value }) => field.onChange(value)}
						/>
					)}
				/>
			</Content>

			<Footer>
				<UiButton
					className="w-full"
					variant="main"
					loading={isLoading}
					type="submit"
				>
					<div>
						{capitalize(
							t(LANG_KEYS.SAVE.key, LANG_KEYS.SAVE.value)
						)}
					</div>
				</UiButton>
			</Footer>
		</Form>
	);
};

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 35px;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
`;

const Footer = styled.div``;

const InnerFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	padding: 0.85rem 1.75rem;
`;
