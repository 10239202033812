import { List, ListItem } from "./ui.tsx";
import { useTranslation } from "react-i18next";
import { LANG_KEYS } from "@/shared/constants";

export const PricingOptionsListsFree = () => {
  const { t } = useTranslation();
  return (
    <List>
      <ListItem>
        {t(
          LANG_KEYS.WEB_AND_MOBILE_RECORDING.key,
          LANG_KEYS.WEB_AND_MOBILE_RECORDING.value,
        )}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.UP_TO_X_MINUTES_PER_NOTE.key,
          LANG_KEYS.UP_TO_X_MINUTES_PER_NOTE.value,
          { minutes: "6" },
        )}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.X_MINUTES_PER_MONTH.key,
          LANG_KEYS.X_MINUTES_PER_MONTH.value,
          { minutes: "30" },
        )}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.RECORD_IN_ANY_LANGUAGE.key,
          LANG_KEYS.RECORD_IN_ANY_LANGUAGE.value,
        )}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.SAVE_UP_TO_X_NOTES.key,
          LANG_KEYS.SAVE_UP_TO_X_NOTES.value,
          { notes: "10" },
        )}
      </ListItem>
    </List>
  );
};
