export const TRANSCRIBE_SOCKET_COMMANDS = {
  //ERRORS
  USER_ID_NOT_FOUND: "User id not found",
  UNSUPPORTED_FORMAT: "Unsupported file format",
  UNDETECTED_LANGUAGE: "Undetected language",

  //STEP
  START_WAV_CONVERTING: "Converting to wav format",
  DONE_WAV_CONVERTING: "Done Converting to wav format",
  //
  START_LANG_DETECTION: "Language detection",
  DONE_LANG_DETECTION: "Done Language detection",
  //
  START_TRANSCRIBING: "Transcribe original text",
  CHUNK_TRANSCRIBING: "Chunk Transcribing",
  DONE_TRANSCRIBING: "Done Transcribe original text",
  //MESSAGES
  CONNECTED: "CONNECTED",
};
