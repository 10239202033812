import { useUserStore } from "@/entities";
import { useMutation } from "@tanstack/react-query";
import { userControllerDeleteMe } from "@/shared/api/generated.ts";

export function useDeleteAccountForm() {
  const userStore = useUserStore();

  const formMutation = useMutation({
    mutationFn: userControllerDeleteMe,
    async onSuccess() {
      userStore.logout();
    },
  });

  return {
    handleSubmit: () => formMutation.mutate({}),
    isLoading: formMutation.isPending,
  };
}
