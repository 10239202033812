import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";

// import LanguageDetector from "i18next-browser-languagedetector";
import { locizePlugin } from "locize";

const locizeOptions = {
  projectId: import.meta.env.VITE_TRANSLATION_PROJECT_ID,
  apiKey: import.meta.env.VITE_TRANSLATION_API_KEY,
};

i18n
  .use(Backend)
  .use(locizePlugin)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: true,
    backend: locizeOptions,
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "languageChanged editorSaved",
    },
  });

export default i18n;
