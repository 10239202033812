import { useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { UiBackdrop } from "@/shared/ui";
import { RecordOptionsModalCard } from "@/features";
import { useRecordOptionsStore } from "@/entities";
import { capitalize } from "@/shared/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LANG_KEYS } from "@/shared/constants";

export const OptionsSettings = () => {
  const recordOptionsStore = useRecordOptionsStore();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <TriggerWrapper>
        <div>
          {t(LANG_KEYS.SUMMARY_LANGUAGE.key, LANG_KEYS.SUMMARY_LANGUAGE.value)}:
        </div>
        <TriggerButton onClick={() => setIsOpen(true)}>
          {capitalize(
            `${t(
              `${recordOptionsStore.notesLang}`,
              `${recordOptionsStore.notesLang}`,
            )} (${t(LANG_KEYS.EDIT.key, LANG_KEYS.EDIT.value)})`,
          )}
        </TriggerButton>
      </TriggerWrapper>

      <AnimatePresence mode="wait">
        {isOpen && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 0 }}
          >
            <UiBackdrop handleClose={closeModal}>
              <RecordOptionsModalCard onClose={closeModal} />
            </UiBackdrop>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
};

const TriggerWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 1.125rem;
  align-items: center;
  color: rgb(75, 85, 99);
  text-align: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const TriggerButton = styled.button`
  text-decoration: underline;
`;
