import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LANG_KEYS } from "@/shared/constants";
import { capitalize } from "@/shared/utils";
import { FC, useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import {
  UiBackdrop,
  UiButton,
  UiInput,
  UiPaper,
  UiTextarea,
} from "@/shared/ui";
import styles from "./styles.module.scss";
import { useSuggestionForm } from "./model.ts";

interface Props {
  triggerMenu?: boolean;
}

export const LeaveSuggestion: FC<Props> = ({ triggerMenu }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    if (isLoading) return;
    setShowModal(false);
  };

  const { isLoading, register, errors, handleSubmit } = useSuggestionForm(() =>
    setShowModal(false),
  );

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <Trigger $triggerMenu={!!triggerMenu} type="button" onClick={handleOpen}>
        {capitalize(
          t(LANG_KEYS.HAVE_A_SUGGESTION.key, LANG_KEYS.HAVE_A_SUGGESTION.value),
        )}
      </Trigger>

      <AnimatePresence mode="wait">
        {showModal && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            className="fixed z-50"
          >
            <UiBackdrop handleClose={handleClose}>
              <UiPaper
                className={styles.suggestionCard}
                isLoading={isLoading}
                onCrossClick={handleClose}
              >
                <Form onSubmit={handleSubmit}>
                  <Title>
                    {capitalize(
                      t(
                        LANG_KEYS.HELP_US_ENHANCE_THE_AUDIONOTES_EXPERIENCE.key,
                        LANG_KEYS.HELP_US_ENHANCE_THE_AUDIONOTES_EXPERIENCE
                          .value,
                      ),
                    )}
                  </Title>

                  <UiInput
                    wrapperClassname="w-full"
                    label={capitalize(
                      t(LANG_KEYS.YOUR_NAME.key, LANG_KEYS.YOUR_NAME.value),
                    )}
                    placeholder={capitalize(
                      t(LANG_KEYS.OPTIONAL.key, LANG_KEYS.OPTIONAL.value),
                    )}
                    {...register("name", { required: false })}
                  />
                  <UiInput
                    wrapperClassname="w-full"
                    error={errors?.email?.message}
                    label={capitalize(
                      t(LANG_KEYS.YOUR_EMAIL.key, LANG_KEYS.YOUR_EMAIL.value),
                    )}
                    placeholder={capitalize(
                      t(LANG_KEYS.OPTIONAL.key, LANG_KEYS.OPTIONAL.value),
                    )}
                    {...register("email", {
                      required: false,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t(
                          LANG_KEYS.INVALID_EMAIL_FORMAT.key,
                          LANG_KEYS.INVALID_EMAIL_FORMAT.value,
                        ),
                      },
                    })}
                  />
                  <UiTextarea
                    wrapperClassname="w-full"
                    error={errors?.text?.message}
                    label={capitalize(
                      t(LANG_KEYS.SUGGESTION.key, LANG_KEYS.SUGGESTION.value),
                    )}
                    placeholder={capitalize(
                      t(
                        LANG_KEYS
                          .WE_VALUE_YOUR_INPUT_PLEASE_SHARE_YOUR_SUGGESTIONS_ON_HOW_WE_CAN_IMPROVE_AUDIONOTES_SERVICE
                          .key,
                        LANG_KEYS
                          .WE_VALUE_YOUR_INPUT_PLEASE_SHARE_YOUR_SUGGESTIONS_ON_HOW_WE_CAN_IMPROVE_AUDIONOTES_SERVICE
                          .value,
                      ),
                    )}
                    {...register("text", {
                      required: {
                        value: true,
                        message: t(
                          LANG_KEYS.REQUIRED.key,
                          LANG_KEYS.REQUIRED.value,
                        ),
                      },
                    })}
                  />

                  <UiButton
                    loading={isLoading}
                    type="submit"
                    className="w-full"
                    variant="main"
                  >
                    <div>
                      {capitalize(t(LANG_KEYS.SEND.key, LANG_KEYS.SEND.value))}
                    </div>
                  </UiButton>
                </Form>
              </UiPaper>
            </UiBackdrop>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Trigger = styled.button<{ $triggerMenu: boolean }>`
  color: #475569;
  cursor: pointer;
  font-size: ${(props) => (props.$triggerMenu ? "16px" : "14px")};
  ${(props) =>
    props.$triggerMenu &&
    `
  padding: 10px 16px;
  width: 100%;
  text-align: left;
  `}
  &:hover {
    color: #0f172a;
  }

  &:hover {
    ${(props) =>
      props.$triggerMenu &&
      `
      background-color: #f9fafb;
      `};
  }
`;

const Title = styled.h2`
  color: #111827;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
`;
