import { forwardRef, InputHTMLAttributes, PropsWithRef } from "react";
import styled from "styled-components";
import { UiValidationError } from "./ui-validation-error";
import { StyledTextField } from "./styled";

type Props = {
  name: string;
  label?: string;
  value?: string;
  error?: string;
  type?: "text" | "password" | "number" | "email";
  placeholder?: string;
  wrapperClassname?: string;
} & PropsWithRef<InputHTMLAttributes<HTMLInputElement>>;

export const UiInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder = "",
      type = "text",
      error,
      name,
      value,
      label,
      wrapperClassname,
      ...props
    },
    ref,
  ) => {
    return (
      <Wrapper className={wrapperClassname}>
        {!!label && <Label htmlFor={name}>{label}</Label>}
        <Input
          ref={ref}
          value={value}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          $error={!!error}
          {...props}
        />
        {!!error && <UiValidationError message={error} />}
      </Wrapper>
    );
  },
);

UiInput.displayName = "UiInput";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  color: var(--dark);
  font-size: 14px;
  font-weight: 500;
`;

const Input = styled(StyledTextField)``;
