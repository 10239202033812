import styled from "styled-components";
import { UiButton, UiInput, UiInputPass, UiLink } from "@/shared/ui";
import { useSignInForm } from "@/features/auth/login/model.ts";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";

export const LoginForm = () => {
	const { handleSubmit, errors, register, isLoading } = useSignInForm();
	const { t } = useTranslation();
	return (
		<form onSubmit={handleSubmit}>
			<Inputs>
				<UiInput
					label={t(
						LANG_KEYS.EMAIL_ADDRESS.key,
						LANG_KEYS.EMAIL_ADDRESS.value
					)}
					error={errors?.email?.message}
					{...register("email", {
						required: {
							value: true,
							message: t(
								LANG_KEYS.REQUIRED.key,
								LANG_KEYS.REQUIRED.value
							),
						},
						pattern: {
							value: /\S+@\S+\.\S+/,
							message: t(
								LANG_KEYS.INVALID_EMAIL_FORMAT.key,
								LANG_KEYS.INVALID_EMAIL_FORMAT.value
							),
						},
					})}
				/>

				<UiInputPass
					id="password"
					label={t(LANG_KEYS.PASSWORD.key, LANG_KEYS.PASSWORD.value)}
					error={errors?.password?.message}
					{...register("password", {
						required: {
							value: true,
							message: t(
								LANG_KEYS.REQUIRED.key,
								LANG_KEYS.REQUIRED.value
							),
						},
					})}
				/>
			</Inputs>

			<Actions>
				<Link to={ROUTER_PATHS.AUTH_RESET_PASSWORD}>
					<UiLink variant="purple">
						{t(
							LANG_KEYS.FORGOT_PASSWORD.key,
							LANG_KEYS.FORGOT_PASSWORD.value
						)}
					</UiLink>
				</Link>
			</Actions>

			<UiButton
				variant="main"
				loading={isLoading}
				type="submit"
				className="w-full"
			>
				<div>{t(LANG_KEYS.SIGN_IN.key, LANG_KEYS.SIGN_IN.value)}</div>
			</UiButton>
		</form>
	);
};

const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
`;
const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 24px 0;
`;
