import { useEffect, useState } from "react";
import { UiButton, UiLoading, UiLogo, UiShow } from "@/shared/ui";
import { Link, useNavigate, useParams } from "react-router-dom";
import { authControllerEmailConfirm } from "@/shared/api/generated.ts";
import { decodeJwt } from "@/shared/utils";
import { useUserStore } from "@/entities";
import styled from "styled-components";
import { IoShieldCheckmark } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";

export const ConfirmEmailPage = () => {
	const { code } = useParams();
	const navigate = useNavigate();
	const { login } = useUserStore();
	const [email, setEmail] = useState<string>("");
	const { t } = useTranslation();
	useEffect(() => {
		const confirmEmail = async () => {
			if (!code) {
				navigate(ROUTER_PATHS.HOME, { replace: true });
				return;
			}

			try {
				const { access_token } = await authControllerEmailConfirm(code);
				const { email } = decodeJwt(access_token);
				login(access_token);
				setEmail(email);
			} catch (e) {
				console.log(e);
				navigate(ROUTER_PATHS.HOME, { replace: true });
			}
		};

		confirmEmail();
	}, [code]);

	return (
		<UiShow>
			<UiShow.When isTrue={!email}>
				<UiLoading fullScreen />
			</UiShow.When>
			<UiShow.When isTrue={!!code && !!email}>
				<Wrapper>
					<Link to={ROUTER_PATHS.HOME}>
						<div className="flex justify-center">
							<UiLogo hoverEffect />
						</div>
					</Link>
					<div className="flex flex-col gap-2 items-center">
						<IoShieldCheckmark color="#22c55e" size={50} />
						<h2 className="text-center text-2xl">
							{t(
								LANG_KEYS.EMAIL_CONFIRMED.key,
								LANG_KEYS.EMAIL_CONFIRMED.value
							)}
							<br />
							{email}
						</h2>
					</div>
					<Link to={ROUTER_PATHS.HOME}>
						<UiButton variant="main" className="w-full">
							<div>
								{t(
									LANG_KEYS.MAIN_PAGE.key,
									LANG_KEYS.MAIN_PAGE.value
								)}
							</div>
						</UiButton>
					</Link>
				</Wrapper>
			</UiShow.When>
		</UiShow>
	);
};

const Wrapper = styled.form`
	min-width: 400px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;

	@media screen and (max-width: 425px) {
		min-width: 90%;
	}
`;
