import styled from "styled-components";

type Props = {
	$error: boolean;
};

export const StyledTextField = styled.input<Props>`
	font-size: 14px;
	box-shadow: rgb(255, 255, 255) 0 0 0 0 inset,
		rgb(209, 213, 219) 0 0 0 1px inset, rgba(0, 0, 0, 0.05) 0 1px 2px 0;
	padding: 6px 10px;
	background-color: #fff;
	border-radius: 6px;
	outline: none;
	border: 1px solid grey;

	&:focus {
		box-shadow: rgb(255, 255, 255) 0 0 0 0 inset,
			rgb(79, 70, 229) 0 0 0 2px inset, rgba(0, 0, 0, 0.05) 0 1px 2px 0;
	}

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	${(props: Props) =>
		props.$error &&
		`
      border-color: red;
      box-shadow: none !important;
  `}
`;

export const StyledTextArea = styled.textarea<Props>`
	font-size: 14px;
	box-shadow: rgb(255, 255, 255) 0 0 0 0 inset,
		rgb(209, 213, 219) 0 0 0 1px inset, rgba(0, 0, 0, 0.05) 0 1px 2px 0;
	padding: 6px 10px;
	background-color: #fff;
	border-radius: 6px;
	outline: none;
	border: 1px solid grey;

	&:focus {
		box-shadow: rgb(255, 255, 255) 0 0 0 0 inset,
			rgb(79, 70, 229) 0 0 0 2px inset, rgba(0, 0, 0, 0.05) 0 1px 2px 0;
	}

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	${(props: Props) =>
		props.$error &&
		`
      border-color: red;
      box-shadow: none !important;
  `}
`;
