import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { capitalize } from "@/shared/utils";
import { LeaveSuggestion } from "@/features";

export const UiMainFooter = () => {
  const { t } = useTranslation();
  return (
    <Footer>
      <Wrapper>
        <a href="https://audionotesai.trackdesk.com/sign-up" target="_blank">
          <FooterLink>
            {capitalize(
              t(
                LANG_KEYS.BECOME_AN_AFFILIATE.key,
                LANG_KEYS.BECOME_AN_AFFILIATE.value,
              ),
            )}
          </FooterLink>
        </a>
        <a href={ROUTER_PATHS.PRIVACY_POLICY} target="_blank">
          <FooterLink>
            {capitalize(
              t(LANG_KEYS.PRIVACY_POLICY.key, LANG_KEYS.PRIVACY_POLICY.value),
            )}
          </FooterLink>
        </a>
        <a href={ROUTER_PATHS.TERMS_OF_SERVICE} target="_blank">
          <FooterLink>
            {capitalize(
              t(
                LANG_KEYS.TERMS_OF_SERVICE.key,
                LANG_KEYS.TERMS_OF_SERVICE.value,
              ),
            )}
          </FooterLink>
        </a>
        <LeaveSuggestion />
      </Wrapper>
      <CopyrightWrapper>
        <CopyrightText>
          © 2023 Novikov Studio, LLC. All rights reserved.
        </CopyrightText>
      </CopyrightWrapper>
    </Footer>
  );
};

const Footer = styled.div`
  background-color: #fff;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const FooterLink = styled.div`
  color: #4b5563;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #111827;
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const CopyrightText = styled.span`
  color: #6b7280;
  font-size: 12px;
`;
