/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API AudioNotes
 * OpenAPI spec version: 1.0.0
 */
import { createInstance } from "./api-instance";
import type { BodyType } from "./api-instance";
export interface EditAccountDtoReq {
  vocabulary: string;
  webhookUrl: string;
}

export interface GetAccountDtoRes {
  vocabulary: string;
  webhookUrl: string;
}

export interface ProvideNewEmailDtoReq {
  code: string;
  email: string;
}

export interface ProvideCodeDtoRes {
  isRight: boolean;
}

export interface ProvideCodeDtoReq {
  code: string;
}

export interface EditCustomSumaryStyleDtoReq {
  id: string;
  text: string;
  title: string;
}

export interface AddCustomSummaryStyleDtoReq {
  text: string;
  title: string;
}

export type EditRecordOptionsDtoReqSummaryView =
  (typeof EditRecordOptionsDtoReqSummaryView)[keyof typeof EditRecordOptionsDtoReqSummaryView];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EditRecordOptionsDtoReqSummaryView = {
  BULLET_POINTS: "BULLET_POINTS",
  PARAGRAPH_FORM: "PARAGRAPH_FORM",
} as const;

export type EditRecordOptionsDtoReqSummaryLength =
  (typeof EditRecordOptionsDtoReqSummaryLength)[keyof typeof EditRecordOptionsDtoReqSummaryLength];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EditRecordOptionsDtoReqSummaryLength = {
  SHORT: "SHORT",
  MEDIUM: "MEDIUM",
  LONG: "LONG",
} as const;

export type EditRecordOptionsDtoReqNumberOfSpeakers =
  (typeof EditRecordOptionsDtoReqNumberOfSpeakers)[keyof typeof EditRecordOptionsDtoReqNumberOfSpeakers];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EditRecordOptionsDtoReqNumberOfSpeakers = {
  AUTODETECT: "AUTODETECT",
  SINGLE_SPEAKER: "SINGLE_SPEAKER",
  MULTIPLE_SPEAKERS: "MULTIPLE_SPEAKERS",
} as const;

export type EditRecordOptionsDtoReqNotesLang =
  (typeof EditRecordOptionsDtoReqNotesLang)[keyof typeof EditRecordOptionsDtoReqNotesLang];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EditRecordOptionsDtoReqNotesLang = {
  AUTODETECT: "AUTODETECT",
  AFRIKAANS: "AFRIKAANS",
  ARABIC: "ARABIC",
  ARMENIAN: "ARMENIAN",
  AZERBAIJANI: "AZERBAIJANI",
  BELARUSIAN: "BELARUSIAN",
  BOSNIAN: "BOSNIAN",
  BULGARIAN: "BULGARIAN",
  CATALAN: "CATALAN",
  CHINESE: "CHINESE",
  CROATIAN: "CROATIAN",
  CZECH: "CZECH",
  DANISH: "DANISH",
  DUTCH: "DUTCH",
  ENGLISH: "ENGLISH",
  ESTONIAN: "ESTONIAN",
  FINNISH: "FINNISH",
  FRENCH: "FRENCH",
  GALICIAN: "GALICIAN",
  GERMAN: "GERMAN",
  GREEK: "GREEK",
  HEBREW: "HEBREW",
  HINDI: "HINDI",
  HUNGARIAN: "HUNGARIAN",
  ICELANDIC: "ICELANDIC",
  INDONESIAN: "INDONESIAN",
  ITALIAN: "ITALIAN",
  JAPANESE: "JAPANESE",
  KANNADA: "KANNADA",
  KAZAKH: "KAZAKH",
  KOREAN: "KOREAN",
  LATVIAN: "LATVIAN",
  LITHUANIAN: "LITHUANIAN",
  MACEDONIAN: "MACEDONIAN",
  MALAY: "MALAY",
  MARATHI: "MARATHI",
  NEPALI: "NEPALI",
  NORWEGIAN: "NORWEGIAN",
  PERSIAN: "PERSIAN",
  POLISH: "POLISH",
  PORTUGUESE: "PORTUGUESE",
  ROMANIAN: "ROMANIAN",
  RUSSIAN: "RUSSIAN",
  SERBIAN: "SERBIAN",
  SLOVAK: "SLOVAK",
  SLOVENIAN: "SLOVENIAN",
  SPANISH: "SPANISH",
  SWAHILI: "SWAHILI",
  SWEDISH: "SWEDISH",
  TAGALOG: "TAGALOG",
  TAMIL: "TAMIL",
  THAI: "THAI",
  TURKISH: "TURKISH",
  UKRAINIAN: "UKRAINIAN",
  URDU: "URDU",
  VIETNAMESE: "VIETNAMESE",
  WELSH: "WELSH",
} as const;

export interface EditRecordOptionsDtoReq {
  notesLang: EditRecordOptionsDtoReqNotesLang;
  numberOfSpeakers: EditRecordOptionsDtoReqNumberOfSpeakers;
  selectedRecordSummaryStyleId: string;
  summaryLength: EditRecordOptionsDtoReqSummaryLength;
  summaryView: EditRecordOptionsDtoReqSummaryView;
}

export type GetRecordOptionsDtoResSummaryView =
  (typeof GetRecordOptionsDtoResSummaryView)[keyof typeof GetRecordOptionsDtoResSummaryView];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRecordOptionsDtoResSummaryView = {
  BULLET_POINTS: "BULLET_POINTS",
  PARAGRAPH_FORM: "PARAGRAPH_FORM",
} as const;

export type GetRecordOptionsDtoResSummaryLength =
  (typeof GetRecordOptionsDtoResSummaryLength)[keyof typeof GetRecordOptionsDtoResSummaryLength];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRecordOptionsDtoResSummaryLength = {
  SHORT: "SHORT",
  MEDIUM: "MEDIUM",
  LONG: "LONG",
} as const;

export type GetRecordOptionsDtoResNumberOfSpeakers =
  (typeof GetRecordOptionsDtoResNumberOfSpeakers)[keyof typeof GetRecordOptionsDtoResNumberOfSpeakers];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRecordOptionsDtoResNumberOfSpeakers = {
  AUTODETECT: "AUTODETECT",
  SINGLE_SPEAKER: "SINGLE_SPEAKER",
  MULTIPLE_SPEAKERS: "MULTIPLE_SPEAKERS",
} as const;

export type GetRecordOptionsDtoResNotesLang =
  (typeof GetRecordOptionsDtoResNotesLang)[keyof typeof GetRecordOptionsDtoResNotesLang];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRecordOptionsDtoResNotesLang = {
  AUTODETECT: "AUTODETECT",
  AFRIKAANS: "AFRIKAANS",
  ARABIC: "ARABIC",
  ARMENIAN: "ARMENIAN",
  AZERBAIJANI: "AZERBAIJANI",
  BELARUSIAN: "BELARUSIAN",
  BOSNIAN: "BOSNIAN",
  BULGARIAN: "BULGARIAN",
  CATALAN: "CATALAN",
  CHINESE: "CHINESE",
  CROATIAN: "CROATIAN",
  CZECH: "CZECH",
  DANISH: "DANISH",
  DUTCH: "DUTCH",
  ENGLISH: "ENGLISH",
  ESTONIAN: "ESTONIAN",
  FINNISH: "FINNISH",
  FRENCH: "FRENCH",
  GALICIAN: "GALICIAN",
  GERMAN: "GERMAN",
  GREEK: "GREEK",
  HEBREW: "HEBREW",
  HINDI: "HINDI",
  HUNGARIAN: "HUNGARIAN",
  ICELANDIC: "ICELANDIC",
  INDONESIAN: "INDONESIAN",
  ITALIAN: "ITALIAN",
  JAPANESE: "JAPANESE",
  KANNADA: "KANNADA",
  KAZAKH: "KAZAKH",
  KOREAN: "KOREAN",
  LATVIAN: "LATVIAN",
  LITHUANIAN: "LITHUANIAN",
  MACEDONIAN: "MACEDONIAN",
  MALAY: "MALAY",
  MARATHI: "MARATHI",
  NEPALI: "NEPALI",
  NORWEGIAN: "NORWEGIAN",
  PERSIAN: "PERSIAN",
  POLISH: "POLISH",
  PORTUGUESE: "PORTUGUESE",
  ROMANIAN: "ROMANIAN",
  RUSSIAN: "RUSSIAN",
  SERBIAN: "SERBIAN",
  SLOVAK: "SLOVAK",
  SLOVENIAN: "SLOVENIAN",
  SPANISH: "SPANISH",
  SWAHILI: "SWAHILI",
  SWEDISH: "SWEDISH",
  TAGALOG: "TAGALOG",
  TAMIL: "TAMIL",
  THAI: "THAI",
  TURKISH: "TURKISH",
  UKRAINIAN: "UKRAINIAN",
  URDU: "URDU",
  VIETNAMESE: "VIETNAMESE",
  WELSH: "WELSH",
} as const;

export type SummaryStyleItemType =
  (typeof SummaryStyleItemType)[keyof typeof SummaryStyleItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SummaryStyleItemType = {
  CUSTOM: "CUSTOM",
  DEFAULT: "DEFAULT",
} as const;

export interface SummaryStyleItem {
  id: string;
  text: string;
  title: string;
  type: SummaryStyleItemType;
}

export interface DeleteCustomSummaryStyleDtoRes {
  selectedRecordSummaryStyleId: string;
  summaryStyle: SummaryStyleItem[];
}

export interface AddCustomSummaryStyleDtoRes {
  createdId: string;
  summaryStyle: SummaryStyleItem[];
}

export interface GetRecordOptionsDtoRes {
  notesLang: GetRecordOptionsDtoResNotesLang;
  numberOfSpeakers: GetRecordOptionsDtoResNumberOfSpeakers;
  selectedRecordSummaryStyleId: string;
  summaryLength: GetRecordOptionsDtoResSummaryLength;
  summaryStyle: SummaryStyleItem[];
  summaryView: GetRecordOptionsDtoResSummaryView;
}

export interface ResendConfirmationEmailDtoRes {
  access_token?: string;
  isResent: boolean;
  message: string;
}

export interface UpdateEmailDtoRes {
  access_token: string;
  isRight: boolean;
}

export interface UpdateEmailDtoReq {
  code: string;
}

export interface ProcessResetPasswordDtoReq {
  code: string;
  password: string;
}

export interface ValidateResetPasswordCodeRes {
  email: string;
}

export interface ResetPasswordDtoReq {
  email: string;
}

export interface AuthDtoRes {
  access_token: string;
}

export interface AuthDtoReq {
  email: string;
  password: string;
}

export interface LeaveSuggestionDtoReq {
  email?: string;
  name?: string;
  text: string;
}

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const appControllerLeaveSuggestion = (
  leaveSuggestionDtoReq: BodyType<LeaveSuggestionDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<void>(
    {
      url: `/api/suggestion`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: leaveSuggestionDtoReq,
    },
    options,
  );
};

export const authControllerSignUp = (
  authDtoReq: BodyType<AuthDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<AuthDtoRes>(
    {
      url: `/api/auth/sign-up`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authDtoReq,
    },
    options,
  );
};

export const authControllerSignIn = (
  authDtoReq: BodyType<AuthDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<AuthDtoRes>(
    {
      url: `/api/auth/sign-in`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authDtoReq,
    },
    options,
  );
};

export const authControllerGenerate = (
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<AuthDtoRes>(
    { url: `/api/auth`, method: "GET" },
    options,
  );
};

export const authControllerEmailConfirm = (
  code: string,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<AuthDtoRes>(
    { url: `/api/auth/email-confirm/${code}`, method: "GET" },
    options,
  );
};

export const authControllerResetPassword = (
  resetPasswordDtoReq: BodyType<ResetPasswordDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<void>(
    {
      url: `/api/auth/reset-password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: resetPasswordDtoReq,
    },
    options,
  );
};

export const authControllerProcessResetPass = (
  processResetPasswordDtoReq: BodyType<ProcessResetPasswordDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<AuthDtoRes>(
    {
      url: `/api/auth/reset-password`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: processResetPasswordDtoReq,
    },
    options,
  );
};

export const authControllerValidateResetPasswordCode = (
  code: string,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<ValidateResetPasswordCodeRes>(
    { url: `/api/auth/reset-password/${code}`, method: "GET" },
    options,
  );
};

export const authControllerUpdateUserEmail = (
  updateEmailDtoReq: BodyType<UpdateEmailDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<UpdateEmailDtoRes>(
    {
      url: `/api/auth/update-email`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateEmailDtoReq,
    },
    options,
  );
};

export const userControllerResendConfirmationEmail = (
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<ResendConfirmationEmailDtoRes>(
    { url: `/api/user/resend-confirmation-email`, method: "POST" },
    options,
  );
};

export const userControllerGetRecordOptions = (
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<GetRecordOptionsDtoRes>(
    { url: `/api/user/record-options`, method: "GET" },
    options,
  );
};

export const userControllerEditRecordOptions = (
  editRecordOptionsDtoReq: BodyType<EditRecordOptionsDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<GetRecordOptionsDtoRes>(
    {
      url: `/api/user/record-options`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: editRecordOptionsDtoReq,
    },
    options,
  );
};

export const userControllerAddCustomSummaryStyle = (
  addCustomSummaryStyleDtoReq: BodyType<AddCustomSummaryStyleDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<AddCustomSummaryStyleDtoRes>(
    {
      url: `/api/user/add-custom-summary`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: addCustomSummaryStyleDtoReq,
    },
    options,
  );
};

export const userControllerEditCustomSummaryStyle = (
  editCustomSumaryStyleDtoReq: BodyType<EditCustomSumaryStyleDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<SummaryStyleItem[]>(
    {
      url: `/api/user/edit-custom-summary`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: editCustomSumaryStyleDtoReq,
    },
    options,
  );
};

export const userControllerDeleteCustomSummaryStyle = (
  id: string,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<DeleteCustomSummaryStyleDtoRes>(
    { url: `/api/user/delete-custom-summary/${id}`, method: "DELETE" },
    options,
  );
};

export const userControllerStartChangeEmail = (
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<void>(
    { url: `/api/user/start-change-email`, method: "POST" },
    options,
  );
};

export const userControllerProvideStartChangeEmailCode = (
  provideCodeDtoReq: BodyType<ProvideCodeDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<ProvideCodeDtoRes>(
    {
      url: `/api/user/provide-change-email-start-code`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: provideCodeDtoReq,
    },
    options,
  );
};

export const userControllerProvideNewEmail = (
  provideNewEmailDtoReq: BodyType<ProvideNewEmailDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<void>(
    {
      url: `/api/user/provide-new-email`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: provideNewEmailDtoReq,
    },
    options,
  );
};

export const userControllerGetAccountInfo = (
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<GetAccountDtoRes>(
    { url: `/api/user/account`, method: "GET" },
    options,
  );
};

export const userControllerEditAccountInfo = (
  editAccountDtoReq: BodyType<EditAccountDtoReq>,
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<GetAccountDtoRes>(
    {
      url: `/api/user/account`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: editAccountDtoReq,
    },
    options,
  );
};

export const userControllerDeleteMe = (
  options?: SecondParameter<typeof createInstance>,
) => {
  return createInstance<void>({ url: `/api/user`, method: "DELETE" }, options);
};

export type AppControllerLeaveSuggestionResult = NonNullable<
  Awaited<ReturnType<typeof appControllerLeaveSuggestion>>
>;
export type AuthControllerSignUpResult = NonNullable<
  Awaited<ReturnType<typeof authControllerSignUp>>
>;
export type AuthControllerSignInResult = NonNullable<
  Awaited<ReturnType<typeof authControllerSignIn>>
>;
export type AuthControllerGenerateResult = NonNullable<
  Awaited<ReturnType<typeof authControllerGenerate>>
>;
export type AuthControllerEmailConfirmResult = NonNullable<
  Awaited<ReturnType<typeof authControllerEmailConfirm>>
>;
export type AuthControllerResetPasswordResult = NonNullable<
  Awaited<ReturnType<typeof authControllerResetPassword>>
>;
export type AuthControllerProcessResetPassResult = NonNullable<
  Awaited<ReturnType<typeof authControllerProcessResetPass>>
>;
export type AuthControllerValidateResetPasswordCodeResult = NonNullable<
  Awaited<ReturnType<typeof authControllerValidateResetPasswordCode>>
>;
export type AuthControllerUpdateUserEmailResult = NonNullable<
  Awaited<ReturnType<typeof authControllerUpdateUserEmail>>
>;
export type UserControllerResendConfirmationEmailResult = NonNullable<
  Awaited<ReturnType<typeof userControllerResendConfirmationEmail>>
>;
export type UserControllerGetRecordOptionsResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetRecordOptions>>
>;
export type UserControllerEditRecordOptionsResult = NonNullable<
  Awaited<ReturnType<typeof userControllerEditRecordOptions>>
>;
export type UserControllerAddCustomSummaryStyleResult = NonNullable<
  Awaited<ReturnType<typeof userControllerAddCustomSummaryStyle>>
>;
export type UserControllerEditCustomSummaryStyleResult = NonNullable<
  Awaited<ReturnType<typeof userControllerEditCustomSummaryStyle>>
>;
export type UserControllerDeleteCustomSummaryStyleResult = NonNullable<
  Awaited<ReturnType<typeof userControllerDeleteCustomSummaryStyle>>
>;
export type UserControllerStartChangeEmailResult = NonNullable<
  Awaited<ReturnType<typeof userControllerStartChangeEmail>>
>;
export type UserControllerProvideStartChangeEmailCodeResult = NonNullable<
  Awaited<ReturnType<typeof userControllerProvideStartChangeEmailCode>>
>;
export type UserControllerProvideNewEmailResult = NonNullable<
  Awaited<ReturnType<typeof userControllerProvideNewEmail>>
>;
export type UserControllerGetAccountInfoResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetAccountInfo>>
>;
export type UserControllerEditAccountInfoResult = NonNullable<
  Awaited<ReturnType<typeof userControllerEditAccountInfo>>
>;
export type UserControllerDeleteMeResult = NonNullable<
  Awaited<ReturnType<typeof userControllerDeleteMe>>
>;
