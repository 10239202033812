import { AuthWidget } from "@/widgets";
import { LoginForm } from "@/features";
import { useTranslation } from "react-i18next";
import { LANG_KEYS } from "@/shared/constants";

export const SignInPage = () => {
	const { t } = useTranslation();
	return (
		<AuthWidget
			title={t(
				LANG_KEYS.SIGN_IN_TO_YOUR_ACCOUNT.key,
				LANG_KEYS.SIGN_IN_TO_YOUR_ACCOUNT.value
			)}
			heading={t(
				LANG_KEYS.NOT_A_MEMBER.key,
				LANG_KEYS.NOT_A_MEMBER.value
			)}
			text={t(LANG_KEYS.SIGN_UP_NOW.key, LANG_KEYS.SIGN_UP_NOW.value)}
			href="/auth/sign-up"
		>
			<LoginForm />
		</AuthWidget>
	);
};
