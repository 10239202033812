import { createRoot } from "react-dom/client";
import App from "./app/app.tsx";
import { Suspense } from "react";
import { UiLoading } from "@/shared/ui";

createRoot(document.getElementById("root")!).render(
  <Suspense fallback={<UiLoading fullScreen />}>
    <App />
  </Suspense>,
);
