import { io } from "socket.io-client";
import { useUserStore } from "@/entities";
import { ChangeEvent, useRef, useState } from "react";
import {
  SUPPORTED_FILE_FORMATS,
  TRANSCRIBE_SOCKET_COMMANDS,
} from "@/shared/constants";
import { getBase64FromFile } from "@/shared/utils";

export function useTranscribeSocket() {
  const url = import.meta.env.VITE_TRANCRIBE_SOCKET_URL;
  const { access_token } = useUserStore();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [received, setReceived] = useState<any>([]);
  const [chunks, setChunks] = useState<string[]>([]);
  const [original, setOriginal] = useState("");
  const [loading, setLoading] = useState(false);

  const socket = io(url, {
    autoConnect: false,
    auth: {
      token: access_token,
    },
  });

  const updateReceived = (value: string) => {
    setReceived((prev) => [...prev, value]);
  };
  const updateChunks = (value: string) => {
    setChunks((prev) => [...prev, value]);
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) return;

    const nameArr = file.name.split(".");

    const extension = nameArr.pop()!;

    if (!SUPPORTED_FILE_FORMATS.includes(extension)) {
      //TODO NOTIFY ABOUT UNSUPPORTED FILE FORMAT
      console.log("Unsupported file format");
    }
    const base64 = await getBase64FromFile(file);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    setReceived(["Connecting ..."]);
    socket.connect();
    setLoading(true);

    socket.on(TRANSCRIBE_SOCKET_COMMANDS.CONNECTED, () => {
      updateReceived("connected");
      socket.emit("start", { base64, extension });
    });

    socket.on(TRANSCRIBE_SOCKET_COMMANDS.START_WAV_CONVERTING, () => {
      updateReceived("Converting to WAV format . . .");
    });
    socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_WAV_CONVERTING, () => {
      updateReceived("Converted to WAV!");
    });
    socket.on(TRANSCRIBE_SOCKET_COMMANDS.START_LANG_DETECTION, () => {
      updateReceived("Language detection . . .");
    });
    socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_LANG_DETECTION, () => {
      updateReceived("Language detected");
    });

    socket.on(TRANSCRIBE_SOCKET_COMMANDS.START_TRANSCRIBING, () => {
      updateReceived("Transcribing original text . . .");
    });
    socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_TRANSCRIBING, ({ data }: any) => {
      updateReceived("Original text transcribed");
      setOriginal(data);
    });
    socket.on(
      TRANSCRIBE_SOCKET_COMMANDS.CHUNK_TRANSCRIBING,
      ({ data }: any) => {
        updateChunks(data);
      },
    );

    socket.on("disconnect", () => {
      setLoading(false);
    });
  };

  return {
    socket,
    handleFileChange,
    received,
    chunks,
    original,
    fileInputRef,
    loading,
  };
}
