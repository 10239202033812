import { useLangStore } from "@/entities";
import { UiDropdown, UiSelect, UiShow } from "@/shared/ui";
import { SelectOption } from "@/shared/types.ts";
import { useTranslation } from "react-i18next";
import { FC } from "react";

type Variant = "dropdown" | "selectable";

interface Props {
  variant?: Variant;
}

export const ChangeLang: FC<Props> = ({ variant = "dropdown" }) => {
  const { data, lang, setLang } = useLangStore();
  const { i18n } = useTranslation();

  const handleSelect = async ({ value }: SelectOption) => {
    await i18n.changeLanguage(value);
    setLang(value);
  };

  return (
    <UiShow>
      <UiShow.When isTrue={variant == "dropdown"}>
        <UiDropdown
          onSelect={handleSelect}
          defaultValue={lang}
          options={data.map((item) => ({
            name: item.nativeName,
            value: item.code,
          }))}
        />
      </UiShow.When>
      <UiShow.When isTrue={variant == "selectable"}>
        <UiSelect
          onSelect={handleSelect}
          defaultValue={lang}
          options={data.map((item) => ({
            name: item.nativeName,
            value: item.code,
          }))}
        />
      </UiShow.When>
    </UiShow>
  );
};
