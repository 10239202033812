import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { decodeJwt } from "@/shared/utils";
import { authControllerGenerate } from "@/shared/api/generated.ts";

interface Store {
  access_token: string;
  email: string;
  isEmailConfirmed: boolean;
  client_access_token: string;

  login: (access_token: string) => void;
  logout: () => void;
  generateClientAuth: () => Promise<string>;
}

export const useUserStore = create<Store>()(
  devtools(
    persist(
      (set, get) => ({
        access_token: "",
        email: "",
        isEmailConfirmed: false,
        client_access_token: "",

        login: (access_token: string) => {
          const { isEmailConfirmed, email } = decodeJwt(access_token);
          set({ isEmailConfirmed, email, access_token });
        },

        logout: () => {
          const clientToken = get().client_access_token;
          set({
            isEmailConfirmed: false,
            access_token: clientToken,
            email: "",
          });
        },
        generateClientAuth: async () => {
          const { access_token } = await authControllerGenerate();
          set({ access_token, client_access_token: access_token });
          return access_token;
        },
      }),
      {
        name: "user-store",
      },
    ),
    { name: "UserStore", enabled: true },
  ),
);
