import type { AxiosRequestConfig } from "axios";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useLangStore, useUserStore } from "@/entities";

export const apiInstance = axios.create({
	withCredentials: true,
	baseURL: import.meta.env.VITE_API_URL,
});

export const createInstance = async <T>(
	config: AxiosRequestConfig,
	options?: AxiosRequestConfig
): Promise<T> => {
	try {
		const token = useUserStore.getState().access_token;
		const activeLang = useLangStore.getState().lang;

		config.headers = {
			...config.headers,
			"active-lang": activeLang ?? "en",
		};

		if (token) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${token}`,
			};
		}

		const response = await apiInstance({
			...config,
			...options,
		});
		return response.data;
	} catch (error) {
		const axiosError = error as AxiosError<{
			message: string;
			error: string;
			statusCode: number;
		}>;

		if (axiosError.response) {
			const errorMessage =
				axiosError.response.data.message || "Something went wrong";
			toast.error(errorMessage, { autoClose: 10000, closeOnClick: true });
		} else {
			toast.error("Something went wrong", {
				autoClose: 10000,
				closeOnClick: true,
			});
		}

		throw error;
	}
};

export type BodyType<Data> = Data;
export type ErrorType<Error> = AxiosError<Error>;
