import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LANG_KEYS } from "@/shared/constants";

export const MicroRecord = () => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<RecordButton>
				<MicroImg src="/microphone.svg" alt="" />
			</RecordButton>

			<StartLabel>
				{t(
					LANG_KEYS
						.TAP_ON_THE_MICROPHONE_BUTTON_ABOVE_TO_START_YOUR_RECORDING
						.key,
					LANG_KEYS
						.TAP_ON_THE_MICROPHONE_BUTTON_ABOVE_TO_START_YOUR_RECORDING
						.value
				)}
			</StartLabel>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
`;

const RecordButton = styled.button`
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--main-purple);
	padding: 30px;
	border-radius: 50%;
	transition: all 0.3s ease;

	&:hover {
		background-color: #6366f1;
	}

	&:active {
		transform: scale(0.75);
	}

	&:hover img {
		transform: scale(1.25);
	}
`;

const MicroImg = styled.img`
	transition: all 0.3s ease;
	width: 42px;
	height: 42px;
`;

const StartLabel = styled.div`
	font-size: 1.125rem;
	text-align: center;
	color: rgb(75, 85, 99);
`;
